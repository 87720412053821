import { useState, useEffect } from 'react'
import clasess from './SuccessPage.module.scss'

import { useSearchParams, useNavigate } from 'react-router-dom'

import { decryptData } from '../../../utils/helper_functions'


const SuccessPage = (props) => {
    const navigate = useNavigate()
    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT

    const prompt_uuid = decryptData(localStorage.getItem('prompt_uuid', null), aes_salt_key)
    const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)
    const prompt_chatroom_key =  localStorage.getItem('prompt_chat_room', '')
    const prompt_agent_name = localStorage.getItem('prompt_agent_name', 'natasha')

    const [queryParameters] = useSearchParams()
    const lowprofilecode = queryParameters.get('lowprofilecode')
    const terminalnumber = queryParameters.get('terminalnumber')


    // states 
    // const [showDiv, setShowDiv] = useState(false)
    const [tickTimer, setTickTimer] = useState(7)
    const [verificationSuccessful, setVerificationSuccessful] = useState(false)
    const [verificationFailed, setVerificationFailed] = useState(false)
    const [goToChat, setGoToChat] = useState(false)



    const handle_verify_payment = async() => {
        console.log("Verifying payment :", prompt_uuid)
        const res = await fetch(`${API_URL}/api/v2/subscribe`, {
            method: 'POST',
            headers: {
                'Authorization':`Bearer ${prompt_access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                lowprofilecode:lowprofilecode,
                terminalnumber:terminalnumber,
            })
        })
        const res_status = res.status
        const data = await res.json()
        console.log('\n\n\nverify payment res :', res_status, data)
        if(res_status===200){
            setGoToChat(true)
            setTimeout(() => {
                setVerificationSuccessful(true)
                props.setShowLoading(false)
            }, 1000);

        }else{
            if (res_status===401){
                // access token expired so lets send the user to login page
                props.remove_local_storage()
                navigate('/login')
            }else{
                // internet issue or something went wrong
                setGoToChat(true)
                setTimeout(() => {
                    setVerificationFailed(true)
                    props.setShowLoading(false)
                }, 1000);
            }
        }
    }

    useEffect(()=>{
        handle_verify_payment()
    }, [])


    useEffect(()=>{
        console.log("\n\nHERE 1")
        if(goToChat){
            if(tickTimer === 0){
                localStorage.removeItem("verify_subscription")
                navigate(`/agent/${prompt_agent_name}/${prompt_chatroom_key}`)
            }else{
                setTimeout(() => {setTickTimer(tickTimer-1)}, 1000);
            }
        }
    }, [goToChat, tickTimer])



    return ( 
        <div className={clasess.container}>

            {!verificationFailed && !verificationSuccessful && <h1>Checking subscription status...</h1>}
            {verificationFailed &&
                <>
                    <h1>Oh no! Something went wrong.</h1>
                    <p>Redirecting to chat in {tickTimer} seconds.</p>
                </>
            }
            {verificationSuccessful && 
                <>
                    <h1>Congratulations!</h1>
                    <p>Subscription Successful. Redirecting to chat in {tickTimer} seconds.</p>
                </>
            }
        </div>
     );
}
 
export default SuccessPage;