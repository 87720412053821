
import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
            user:'', // both. For admin user=email, for normal user, user=uuid
            email: '',
            hasSubscription:false, //for user only
            subscriptionData:{},
            
            accessToken: null, // both admin and user
            isAdmin: false, // for admin only
            isModerator: false, // for admin only
    },
    reducers: {
        setUser(state, action) {
            state.user = action.payload
        },
        setEmail(state, action) {
            state.email = action.payload
        },
        setHasSubscription(state, action) {
            state.hasSubscription = action.payload
        },
        setSubscriptionData(state, action){
            state.subscriptionData = action.payload
        },
        setAccessToken(state, action) {
            state.accessToken = action.payload
        },
        setIsAdmin(state, action) {
            state.isAdmin = action.payload
        },
        setIsModerator(state, action) {
            state.isModerator = action.payload
        },
    }
})
export const userActions = userSlice.actions
export default userSlice