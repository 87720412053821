import classes from './SliderPage.module.scss'
import cross_whtie from '../../assets/icons/cross_white.svg'

import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { uiActions } from '../../store/ui-slice'
import { userActions } from '../../store/user-slice'

import { decryptData } from '../../utils/helper_functions'
import { ImpulseSpinner } from 'react-spinners-kit'


const SliderPage = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const hasSubscription = useSelector(state => state.user.hasSubscription)
    const isAdminLoggedIn = useSelector(state => state.ui.isAdminLoggedIn)
    const getSubscriptionData = useSelector(state => state.ui.getSubscriptionData)
    const prev_subscriptionData = useSelector(state => state.user.subscriptionData)

    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT
    const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)
    

    // states
    const [loading, setLoading] = useState(false)
    const [subscriptionData, setSubscriptionData] = useState({})

    // chat page alert states
    const [failedMsg, setFailedMsg] = useState('') 
    const [failed, setFailed] = useState(false)



    const get_latest_subscription_data = async() =>{
        setLoading(true)
        console.log("Getting latest subscription data")
        const res = await fetch(`${API_URL}/api/v2/subscriptiondata`, {
            method: 'GET',
            headers: {'Authorization':`Bearer ${prompt_access_token}`}
        })
        const res_status = res.status
        const data = await res.json()
        console.log('\nGetsubscription data res :', res_status, data)

        if(res_status===200){
            if(data['hasSubscription']){
                const subscription_data = data['subscription_data'] 
                setSubscriptionData(subscription_data)
                dispatch(userActions.setSubscriptionData(subscription_data))
                dispatch(userActions.setHasSubscription(true))
            }
            setTimeout(() => {setLoading(false)}, 1000);
            dispatch(uiActions.setGetSubscriptionData(false))
        }else{
            if(res_status===401){
                // access token expired so lets send the user to login page
                props.remove_local_storage()
                navigate('/login')
            }else{
                // internet issue or something went wrong
                setFailedMsg(data['error'])
                setFailed(true)
                setTimeout(() => {setLoading(false)}, 1000);
                setTimeout(() => {setFailed(false)}, 3000);
            }
        }
    }

    useEffect(()=>{
        if(getSubscriptionData){
            console.log("---------> isAdminLoggedIn is :", isAdminLoggedIn)
            get_latest_subscription_data()
        }else{
            console.log("---------> HERE is :", hasSubscription, getSubscriptionData)
            if(hasSubscription){setSubscriptionData(prev_subscriptionData)}
        }
    }, [])




    // ################################### all functions ###################################
    const handleLogout = () => {
        console.log("handle logout clicked :", isAdminLoggedIn)

        if(isAdminLoggedIn){
            localStorage.removeItem('prompt_admin_user') // email = admin user
            localStorage.removeItem('prompt_admin_role')
            localStorage.removeItem('prompt_admin_access_token')
            localStorage.removeItem('prompt_admin_refresh_token')

            dispatch(uiActions.setIsAdminLoggedIn(false))       
            dispatch(userActions.setIsAdmin(false))
            dispatch(userActions.setIsModerator(false))
            dispatch(userActions.setAccessToken(null))

        }else{
            props.remove_local_storage()
        }
    }


    return ( 
        <div className={classes.container}>
            <div className={classes.slider_main_div}>
                <div className={classes.slider_main_div_top}>
                    <div className={classes.slider_top_div}>
                        <div className={classes.slider_top_div_left}>
                            <div className={classes.slider_title}>
                                Hello & Welcome 👋
                            </div>
                            <div className={classes.slider_subtitle}>
                                You can check your account information and subscription status here. Lets continue your unlimited spiritual journey. 
                            </div>                            
                        </div>
                        <div className={classes.slider_top_div_right}>
                            <div className={classes.slider_cross_btn} onClick={props.toggle_slider_page}>
                                <img className={classes.slider_cross_icon} src={cross_whtie} alt="⤫"/>
                            </div>
                        </div>
                    </div>
                    {!isAdminLoggedIn &&
                    <div className={classes.slider_middle_div}>
                        <div className={classes.slider_middle_div_bottom_left}>
                            <div className={classes.slider_middle_div_top}>
                                Subscription 💰
                                {loading && <div className={classes.loading_div}><ImpulseSpinner size={20} frontColor="#fff" backColor="#ffffff60"/></div>}
                            </div>

                            {hasSubscription ? (
                                <>
                                <div className={classes.slider_middle_div_bottom}>
                                    You're now a astrowize premium user. Your subscription details are as follows:
                                    <div className={classes.subscirption_data_table}>
                                        <div className={classes.subscirption_data_row}>
                                            <div>Subscription Plan </div>
                                            <div>{subscriptionData['plan_name']} ♦️ Tracking ID: {subscriptionData['lowprofilecode']}</div>
                                        </div>
                                        <div className={classes.subscirption_data_row}>
                                            <div>Purchase Date</div>
                                            <div>{subscriptionData['purchase_date']} ♦️ {subscriptionData['active']}</div>
                                        </div>
                                        <div className={classes.subscirption_data_row}>
                                            <div>Amount</div>
                                            <div>{subscriptionData['paid_amount']}</div>
                                        </div>
                                        <div className={classes.subscirption_data_row}>
                                            <div>Validity</div>
                                            <div>{subscriptionData['validity_in_days']} Left</div>
                                        </div>
                                        <div className={classes.subscirption_data_row}>
                                            <div>Expire Date</div>
                                            <div>{subscriptionData['expire_date']}</div>
                                        </div>
                                        <div className={classes.subscirption_data_row}>
                                            <div>Already Expired?</div>
                                            <div>{subscriptionData['expired']}</div>
                                        </div>
                                        
                                    </div>
                                </div>
                                </>
                            ):(
                                <>
                                {!loading && <div className={classes.slider_middle_div_bottom}>Currently you've no subscription plan. Our premium plan stars at $10/month. Click "Get Subscription" to purchase and enjoy unlimited features.</div>}
                                </>
                            )}
                        </div>

                        {hasSubscription?
                            <div className={classes.slider_middle_div_bottom_right}>
                                <button className={classes.logout_btn}>Cancel Subscription</button>
                            </div>
                        :
                        <>
                        {!loading && 
                            <div className={classes.slider_middle_div_bottom_right}>
                                <button className={classes.subscription_btn} onClick={props.handleSubscribe}>Get Subscription</button>
                            </div>}
                        </>}          
                    </div>}
                    <div className={classes.slider_main_div_bottom}>
                        <div className={classes.slider_bottom_div}>
                            <div className={classes.slider_bottom_div_left}>
                                <div className={classes.slider_bottom_div_top}>
                                    Logout 🤔
                                </div>
                                <div className={classes.slider_bottom_div_bottom}>
                                    Do you have another account? Or want to login to a different one instead?
                                </div>
                            </div>

                            <div className={classes.slider_bottom_div_right}>
                                <button className={classes.logout_btn} onClick={handleLogout}>Logout</button>
                            </div>
                        </div>
                        {failed && <div className={classes.failed_alert_div}>{failedMsg}</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default SliderPage;


