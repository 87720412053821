import classes from './AdminDashboard.module.scss'

import NavBar from "../../components/NavBar/NavBar.js";
import EditPrompt from '../EditPrompt/EditPrompt.js'



const AdminDashboard = () => {
    return(
        <>
            <div className={classes.prompt_container}>
                <NavBar/>
                <EditPrompt/> 
            </div>
        </>
    )
}
export default AdminDashboard;