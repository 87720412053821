import classes from './Navbar.module.scss'

import asterisk_white from '../../assets/icons/asterisk_white.svg'
import menu_white from '../../assets/icons/menu_white.svg'

import SliderPage from '../../pages/SliderPage/SliderPage'
import { useState } from 'react'





const Navbar = (props) => {
    const handleSubscribe = props.handleSubscribe
    const remove_local_storage = props.remove_local_storage

    const [showSlider, setShowSlider] = useState(false)


    // show or hide slider page
    const toggle_slider_page = () => {setShowSlider(!showSlider)}


    const slider_props = {
        toggle_slider_page,
        handleSubscribe,
        remove_local_storage,
    }

    return(
        <>
        <div className={classes.container}>
                <div className={classes.navbar_main_left}>
                    <a href={process.env.REACT_APP_PUBLIC_URL} className={classes.navbar_main}>
                        <img src={asterisk_white} alt='Prompt'/>
                        <div>Prompt <small>3.0 Astrology</small></div>
                    </a>
                </div>
                <div className={classes.navbar_main_right} onClick={toggle_slider_page}>
                    <img src={menu_white} alt='menu'/>
                </div>
        </div>
        {showSlider && <SliderPage {...slider_props}/>}
        </>
    )
}

export default Navbar;