import classes from './AdminLogin.module.scss'

// components
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'

import { uiActions } from '../../store/ui-slice'
import { userActions } from '../../store/user-slice'
import { encryptData, validateEmail } from '../../utils/helper_functions'
import { useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import { ImpulseSpinner } from 'react-spinners-kit'
import { useDispatch } from 'react-redux'



const AdminLogin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT

    // all states
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [primaryOtp, setPrimaryOtp] = useState('')
    const [secondaryOtp, setSecondaryOtp] = useState('')

    const [loginLoading, setLoginLoading] = useState(false)
    const [showOtpDiv, setShowOtpDiv] = useState(false) 
    

    // alert states section
    const [showEmptyEmailAlert, setShowEmptyEmailAlert] = useState(false)
    const [showInvalidEmailAlert, setShowInvalidEmailAlert] = useState(false)

    // password issue alert
    const [showPasswordValueError, setShowPasswordValueError] = useState(false)
    const [showOTPValueError, setShowOTPValueError] = useState(false)

    // login successful/failed alert
    const [showLoginSuccessfulAlert, setShowLoginSuccessfulAlert] = useState(false)
    const [showLoginFailedAlert, setShowLoginFailedAlert] = useState(false)
    



    // ############################## useEffect section ##############################
    useEffect(()=>{setLoginLoading(false)}, [])


    // ############################## all helper functions section ##############################
    // initial alert state 
    const initial_alert_state = () => {
        setShowEmptyEmailAlert(false)
        setShowInvalidEmailAlert(false)

        setShowPasswordValueError(false)
        setShowOTPValueError(false)

        setShowLoginSuccessfulAlert(false)
        setShowLoginFailedAlert(false)
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            if(showOtpDiv){handleAdminLogin()}else{handleContinueToAdminLogin()}
        }
    }


    // ############################## all functions section ##############################
    const handleContinueToAdminLogin = async() => {
        console.log('Continue to admin login clicked :', email, password)
        setLoginLoading(true)
        initial_alert_state()

        // validate email
        if(email===""){
            setShowEmptyEmailAlert(true)
            setLoginLoading(false)
            setTimeout(()=>{setShowEmptyEmailAlert(false)}, 4000)
            return
        }else if(!validateEmail(email)){
            setShowInvalidEmailAlert(true)
            setLoginLoading(false)
            setTimeout(()=>{setShowInvalidEmailAlert(false)}, 4000)
            return
        }

        // validate password
        if(password==="" || password.length<8){
            setShowPasswordValueError(true)
            setLoginLoading(false)
            setTimeout(()=>{setShowPasswordValueError(false)}, 4000)
            return
        }
        const res = await fetch(`${API_URL}/api/v2/adminlogin`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'email':email,
                'password':password,
                'otp_verification':false
            })
        })
        const res_status = res.status
        const data = await res.json()
        console.log('res in admin continue to login is :', res_status, data)
        if(res_status === 200){
            setShowOtpDiv(true)
        }else{
            setShowLoginFailedAlert(false)
        }
        setLoginLoading(false)
    }


    // admin login functionality
    const handleAdminLogin = async() => {
        setLoginLoading(true)
        console.log("Admin login clicked")
        initial_alert_state()

        const primary_otp_int = parseInt(primaryOtp)
        const secondary_otp_int = parseInt(secondaryOtp)
        console.log("Admin login btn clicked :", email, password, primary_otp_int, typeof primary_otp_int, secondary_otp_int, typeof secondary_otp_int, showOtpDiv)
        if(primaryOtp.length<4 || primaryOtp.length>6 || !Number.isInteger(primary_otp_int) || secondaryOtp.length<4 || secondaryOtp.length>6 || !Number.isInteger(secondary_otp_int)){
            setShowOTPValueError(true)
            setLoginLoading(false)
            setTimeout(()=>{setShowOTPValueError(false)}, 4000)
            return
        }
        // sending request to api
        const res = await fetch(`${API_URL}/api/v2/adminlogin`, {
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                'otp_verification':true,
                'email':email,
                'otp1':primary_otp_int,
                'otp2':secondary_otp_int,
            })
        })
        const res_status = res.status
        const data = await res.json()
        console.log('res in admin login is :', res_status, data)
        if(res_status === 200){
            setShowLoginSuccessfulAlert(true)

            // set the access token and other creds to localstorage
            localStorage.setItem('prompt_admin_user', data['user']) // email = admin user
            localStorage.setItem('prompt_admin_role', data['role'])
            localStorage.setItem('prompt_admin_access_token', encryptData(data['access_token'], aes_salt_key))
            localStorage.setItem('prompt_admin_refresh_token', encryptData(data['refresh_token'], aes_salt_key))
            
            if(data['role']==="ADMIN"){
                dispatch(userActions.setIsAdmin(true))
            }else{
                dispatch(userActions.setIsModerator(true))
            }
            dispatch(userActions.setAccessToken(data['access_token']))
            dispatch(uiActions.setIsAdminLoggedIn(true))


            setTimeout(()=>{
                setShowLoginSuccessfulAlert(false)
                console.log("Navigating to /admin/home from admin login")
                navigate('/admin/edit')
            }, 3000)
        }else{
            setShowLoginFailedAlert(true)
            setTimeout(()=>{setShowLoginFailedAlert(false)}, 4000)
        }
        setLoginLoading(false)
    }




    return (
        <div className={classes.container}>
            <div className={classes.login_container}>
                {loginLoading ? (
                    <div className={classes.alert_loading_div}>
                        <ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>
                    </div>
                ):(
                    <div className={classes.login_container_form}>
                        <div className={classes.login_container_form_top}>
                            <div className={classes.title_div}>Admin Login</div>
                            <div className={classes.input_div}>
                                {/* email, password and confirm password section */}
                                {showOtpDiv?(
                                    <>
                                    <Input label='One Time Password' type='text' placeholder='Primary OTP' value={primaryOtp} onChange={setPrimaryOtp} onKeyDown={handleKeyDown}/>
                                    <Input label='One Time Password' type='text' placeholder='Secondary OTP' value={secondaryOtp} onChange={setSecondaryOtp} onKeyDown={handleKeyDown}/>
                                    </>
                                ):(
                                    <>
                                    <Input label='Email' type='email' placeholder={email!==""?email:'mail@example.com'} value={email} onChange={setEmail} onKeyDown={handleKeyDown}/>
                                    <Input label='Password' type='password' placeholder='********' value={password} onChange={setPassword} onKeyDown={handleKeyDown}/>
                                    </>
                                )}


                                {/* remark section */}
                                <div className={classes.login_remark}>
                                    {showOtpDiv && "An otp is sent to your email address. Please use otp and set password to login."}
                                    {!showOtpDiv && "Enter your email address and continue to login. An unforgettable spiritual & psychic experience is waiting for you!"}
                                </div>
                            </div>
                        </div>
                        <div className={classes.login_container_form_bottom}>
                            <div className={classes.alert_div}>
                                {showEmptyEmailAlert && "Email can't be empty."}
                                {showInvalidEmailAlert && "Invalid Email. Please try again with correct one."}
                                {showPasswordValueError && "Password fields can't be empty and must be 8 characters long."}
                                {showLoginFailedAlert && "Login failed. Please try again with correct credentials."}    

                                {showOTPValueError && "Invalid OTP. Please provide valid otp."}
                                {showLoginSuccessfulAlert && <p className={classes.success_text}>Login successful. Please wait...</p>}
                            </div>
                            <div className={classes.btn_div}>
                                <Button reverse id='admin_login_btn' color='green' btnText={showOtpDiv?"Login":"Continue"} onClick={showOtpDiv?handleAdminLogin:handleContinueToAdminLogin}/>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default AdminLogin;