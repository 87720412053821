import classes from './CustomPopup.module.scss'

import Button from '../Button/Button'
import { ImpulseSpinner } from "react-spinners-kit";


const CustomPopup = (props) => {
    const title = props.title
    const description = props.description
    const yesBtnText = props.yesBtnText
    const noBtnText = props.noBtnText
    const showLoginInstead = props.showLoginInstead
    const popupLoading = props.popupLoading

    const onYes = props.onYes
    const onNo = props.onNo


    return(
        <div className={classes.alert_container}>
            <div className={classes.alert_div}>
                {popupLoading ? (
                        <div className={classes.alert_loading_div}>
                            <ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>
                        </div>
                    ):(
                    <>
                        <div className={classes.alert_title}>{title}</div>
                        <div className={classes.alert_des_div}>
                            <div className={classes.alert_des}>
                                {description}
                            </div>
                            
                            <div className={classes.alert_des_login}>
                                {/* <a href='/login'>Click Here</a> */}
                                {showLoginInstead &&  "Want to login to a different account instead?"}
                            </div>
                        </div>

                        <div className={classes.alert_option}>
                            {noBtnText && <Button reverse id='popup_no_btn' color='red' btnText={noBtnText} onClick={onNo}/>}
                            {yesBtnText && <Button reverse id='popup_yes_btn' color='green' btnText={yesBtnText} onClick={onYes}/>} 
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default CustomPopup;