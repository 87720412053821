import classes from './Button.module.scss'


const Button = (props) => {
    const id = props.id
    const type = props.type
    const disabled = props.disabled
    const color = props.color
    const btnText = props.btnText
    const onClick = props.onClick
    // console.log("Button props are :", props)

    let style = [classes.container,classes.default,classes.blue]
    if(props.reverse) {
        if(color === 'green') style = [classes.container,classes.reverse,classes.reverse_green]
        if(color === 'red') style = [classes.container,classes.reverse,classes.reverse_red]
        if(color === 'blue') style = [classes.container,classes.reverse,classes.reverse_blue]
        if(color === 'orange') style = [classes.container,classes.reverse,classes.reverse_orange]
        if(color === 'purple') style = [classes.container,classes.reverse,classes.reverse_purple]
    }
    else {
        if(color === 'green') style = [classes.container,classes.default,classes.green]
        if(color === 'red') style = [classes.container,classes.default,classes.red]
        if(color === 'orange') style = [classes.container,classes.default,classes.orange]
        if(color === 'purple') style = [classes.container,classes.default,classes.purple]
    }

    if(props.disabled) {
        style = [classes.container,classes.reverse,classes.disabled]
    }


    return (
        <button
            id={id || ''} 
            className={style.join(' ')} 
            type={type || 'button'} 
            disabled={disabled}
            onClick={onClick}
        >
            {btnText}
        </button>
    )
}

export default Button;
