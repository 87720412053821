import CustomPopup from "../../components/CustomPopup/CustomPopup"

import { useNavigate } from "react-router-dom"

const InitialMsgPage = (props) => {
    const navigate = useNavigate()

    // console.log("inintial msg page props :", props)

    return (
        <>
            {!props.showSomethingWentWrongDiv && !props.showInvalidDataPopup && props.showExistingAccountFoundPopUp &&
                <CustomPopup 
                    title={props.popupLoading?'Creating New Account':'Existing Account Found'}
                    description="You're already logged in with an existing account. Want to create new one? Click Continue to proceed."
                    yesBtnText='Continue'
                    noBtnText='Go Back'
                    showLoginInstead={false}
                    popupLoading={props.popupLoading}
                    onYes={()=>{
                            props.setPopupLoading(true)
                            props.create_user_account(true)
                            // setTimeout(() => {props.setPopupLoading(false)}, 1000);
                        }
                    }
                    onNo={()=>{
                        props.force_check_user_credentials(true)
                    }}
                />
            }


            {/* if invalid data provided by url query then show following div */}
            {props.showInvalidDataPopup && 
                <CustomPopup
                    title={props.createUserFailed?'Create User Failed':'Invalid Data Given'}
                    description={props.createUserFailed?props.createUserFailedReason:"Data in url query parameters are incorrect. Please check email, name, phone and zodiac sign values and try again with valid url query parameters."}
                    yesBtnText='Login Instead'
                    noBtnText='Cancel'
                    showLoginInstead={true}
                    popupLoading={props.popupLoading}
                    onYes={()=>{
                        props.remove_local_storage()
                        navigate("/login")
                    }}
                    onNo={()=>{props.force_check_user_credentials(true)}}
                />
            } 


            {/* when network issue occurred or something went wrong while checking access token */}
            {(props.showSomethingWentWrongDiv) &&
                <CustomPopup 
                    title='Something Went Wrong'
                    description="Please check your internet connection and reload the app."
                />
            }
        </>
    )
}

export default InitialMsgPage;