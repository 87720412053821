import classes from './Main.module.scss'

// pages
import Chat from '../Chat/Chat'
import ResetPassword from '../ResetPassword/ResetPassword'
import InitialMsgPage from '../InitialMsgPage/InitialMsgPage'

import SuccessPage from '../Subscription/SuccessPage/SuccessPage'
import FailedPage from '../Subscription/FailedPage/FailedPage'


// components
import NavBar from "../../components/NavBar/NavBar"
import LoginPrompt from '../LoginPrompt/LoginPrompt'


// moduels/packages/libraries
import { useEffect, useState } from "react"
import { Route, Routes, Navigate, useParams, useNavigate, useSearchParams } from 'react-router-dom'

import { GooSpinner } from "react-spinners-kit";


// local modules
// import useWindowSize from '../../assets/hooks/useWindowSize.js'

import { encryptData, decryptData, get_new_access_token, validateEmail } from '../../utils/helper_functions'

// test
import { uiActions } from '../../store/ui-slice'
import { useSelector, useDispatch } from 'react-redux'






const Main = () => {
    const dispatch = useDispatch()
    const isLoggedIn = useSelector(state => state.ui.isLoggedIn)
    const PATH_NAME = window.location.pathname
    const HREF_NAME = window.location.href

    // const navigateToSubscription = useSelector(state => state.ui.navigateToSubscription)
    // const user = useSelector(state => state.user.user)
    // const user_email = useSelector(state => state.user.email)
    // console.log("isLoggedIn in main js is :", isLoggedIn)

    const params = useParams()
    const navigate = useNavigate()
    
    const [queryParameters] = useSearchParams()
    const zodiac_sign = queryParameters.get('sign')
    const name = queryParameters.get('name')
    const email = queryParameters.get('email')
    const phone = queryParameters.get('phone')
    const question_answers = queryParameters.get('qa')
    // console.log('------- url query params are -->>>>>', name, email, phone, zodiac_sign, question_answers);
    console.log('------- custom url query params are -->>>>>', question_answers);

    // const given_agent_name = params.agent_name
    const given_chatroom_key = params.chatroom_key
    // console.log('------- params and chatroom key are -->>>>>', params, given_chatroom_key);

    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT

    const prompt_uuid = decryptData(localStorage.getItem('prompt_uuid', null), aes_salt_key)
    const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)
    const prompt_chatroom_key =  localStorage.getItem('prompt_chat_room', '')
    const prompt_agent_name = localStorage.getItem('prompt_agent_name', 'natasha')
    const verify_subscription = localStorage.getItem('verify_subscription', false)


    // states
    const [showLoading, setShowLoading] = useState(true)
    const [tryAgain, setTryAgain] = useState(true)

    const [showExistingAccountFoundPopUp, setShowExistingAccountFoundPopUp] = useState(false)
    // const [showNoAccountFoundPopUp, setShowNoAccountFoundPopUp] = useState(false)


    const [showInvalidDataPopup, setShowInvalidDataPopup] = useState(false) //if given email or zodiac sign in url is invalid
    const [showSomethingWentWrongDiv, setShowSomethingWentWrongDiv] = useState(false) 
    
    const [createUserFailed, setCreateUserFailed] = useState(false)
    const [createUserFailedReason, setCreateUserFailedReason] = useState('')

    // initialMsgPage states
    const [popupLoading, setPopupLoading] = useState(false)


    // chat page alert states
    const [subscriptionFailedMsg, setSubscriptionFailedMsg] = useState('') 
    const [showSubscriptionFailed, setShowSubscriptionFailed] = useState(false) 



    // ################################################ useEffect Section ################################################
    // ################################################ useEffect Section ################################################
    const force_check_user_credentials = (go_to_login) => {
        setShowLoading(true)
        if(prompt_uuid && prompt_access_token && prompt_agent_name && prompt_chatroom_key){
            // check if access token is valid for uuid and chatroom key. 
            // if not, force him to login and get new access token for 7 days
            check_user_credentails()
        }else{
            // console.log("YES HERE :", go_to_login)
            if(go_to_login){
                remove_local_storage()
                navigate('/login')
            }
            setShowLoading(false)
        }
    }
    // app logic for anonymous user
    useEffect(()=>{
        // console.log("PATHNAME IS :", PATH_NAME, HREF_NAME)
        // console.log('---- main.js useeffect value -->', name, email, phone, zodiac_sign, prompt_uuid, prompt_agent_name, prompt_chatroom_key, PATH_NAME)

        if(email && name && phone && zodiac_sign){
            if(prompt_uuid){
                console.log("Prompt id or existing account found")
                setShowExistingAccountFoundPopUp(true)
                setShowLoading(false)
            }else{
                console.log("\n\ncreate new user account using create_user_account")
                create_user_account(false)
            }
        }else{
            force_check_user_credentials(false)
        }
    }, [])

    


    const check_user_credentails = async() => {
        console.log("checking user access token is valid or not:", prompt_uuid)
        const res = await fetch(`${API_URL}/api/v2/login`, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${prompt_access_token}`,
            }
        })

        const res_status = res.status
        const data = await res.json()
        console.log('\n\n\nvalidate access token res :', res_status, data)
        if(res_status===200){
            dispatch(uiActions.setIsLoggedIn(true))

            if(PATH_NAME.includes('/subscription/')){
                var new_href_name = HREF_NAME.replaceAll("%20", "").replace(process.env.REACT_APP_PUBLIC_URL,'')
                console.log("\n\nHERE SUB confirmation url :", new_href_name, "\n\n")

                setShowLoading(false)
                navigate(new_href_name)
            }else{
                initialization()
            }
        }else{
            dispatch(uiActions.setIsLoggedIn(false))
            if(res_status===401){
                // access token expired so lets send the user to login page
                remove_local_storage()
                navigate('/login')
            }else{
                // internet issue or something went wrong
                setShowSomethingWentWrongDiv(true)
            }
        }
        setTimeout(() => {setShowLoading(false)}, 1000);
    }

    
    // // testing purpose 
    // useEffect(()=>{
    //     console.log("\n\n\nshow main msg page :", (showNoAccountFoundPopUp && !showLoginDiv), (!showExistingAccountFoundPopUp && !showNoAccountFoundPopUp))
    // }, [showNoAccountFoundPopUp])

    // ######################## functions to work with auth, chatroom_token and access_token ########################
    // create annonymous user
    // const create_ann_user = async () => {
    //     console.log("Authenticating user :", API_URL)
    //     const res = await fetch(`${API_URL}/api/v1/auth`, {
    //         method: 'GET',
    //     })
    //     const res_status = res.status
    //     const data = await res.json()
    //     console.log('auth data :', data)

    //     if(res_status === 200) {
    //         localStorage.setItem('prompt_uuid', encryptData(data['uuid'], aes_salt_key))
    //         localStorage.setItem('prompt_access_token', encryptData(data['access_token'], aes_salt_key))
    //         localStorage.setItem('prompt_chat_room', data['chat_token'])
                // navigate(`/agent/${props.prompt_agent_name}/${props.prompt_chatroom_key}`)                        
    //         setShowLoading(false)
    //     }else{
    //         console.log("Creating anonymous user failed, reload the website:", res_status, data)
    //     }
    // }

    // get chatroom key for anonymous user
    const get_chatroom_key = async (uuid, access_token) => {
        console.log("Get chatroom key for ann user :", uuid, access_token)
        const chat_res = await fetch(`${API_URL}/api/v1/chattokens`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${access_token}`,
                'uuid':uuid,
                'prevAccessToken':access_token
            }
        })
        const chat_res_status = chat_res.status
        const chat_data = await chat_res.json()
        if(chat_res_status === 200) {
            localStorage.setItem('prompt_chat_room', chat_data['chat_token'])
        }else{
            if(chat_res_status === 401 && tryAgain){
                await get_new_access_token(uuid, prompt_chatroom_key, API_URL, aes_salt_key)
                const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)
                get_chatroom_key(uuid, prompt_access_token)
                setTryAgain(false)
            }else{
                console.log("Something went wrong when getting chat token :", chat_data, chat_res_status)
            }
        }
    }


    
    const initialization = async() =>{
        console.log("\ninitialization decrypted value :",
                    'prompt_uuid :', prompt_uuid, '\n',
                    'prompt_chatroom_key :', prompt_chatroom_key, '\n',
                    'given_chatroom_key :', given_chatroom_key, '\n',
                    'prompt_agent_name :', prompt_agent_name, '\n')

        if(!prompt_chatroom_key){
            get_chatroom_key(prompt_uuid, prompt_access_token)
            setShowLoading(false)
        }else if(given_chatroom_key !== prompt_chatroom_key){
            setTimeout(()=>{
                navigate(`/agent/${prompt_agent_name}/${prompt_chatroom_key}`)
                setShowLoading(false)
            }, 1000);
        }else{
            console.log("TEST HERE")
            setTimeout(()=>{setShowLoading(false)}, 1000);
        }
    }

 
    const create_user_account = async (enableDelay) => {
        console.log("starting to create account clicked")
        // if invalid email is provided
        if(!validateEmail(email) || !validateZodiacSign(zodiac_sign)){
            console.log("Invalid url data given")
            // setShowExistingAccountFoundPopUp(false)
            setShowInvalidDataPopup(true)
            setShowLoading(false)
            setPopupLoading(false)
            return
        }
        
        console.log("Creating new account")
        const res = await fetch(`${API_URL}/api/v2/user`, {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                name:name,
                email:email,
                phone:phone,
                zodiac_sign:zodiac_sign,
                question_answers:question_answers
            })
        })

        const res_status = res.status
        const data = await res.json()
        console.log('create user account res :', data)

        if(res_status === 200){
            localStorage.setItem('prompt_uid', encryptData(data['uid'], aes_salt_key))
            localStorage.setItem('prompt_uuid', encryptData(data['uuid'], aes_salt_key))
            localStorage.setItem('prompt_access_token', encryptData(data['access_token'], aes_salt_key))
            localStorage.setItem('prompt_chat_room', data['chat_token'])
            localStorage.setItem('prompt_agent_name', data['agent_name'])

            dispatch(uiActions.setIsLoggedIn(true))
            setShowLoading(false)
            setPopupLoading(false)
            navigate(`/agent/${data['agent_name']}/${data['chat_token']}`)

            if(enableDelay){
                setShowLoading(true)
                setTimeout(() => {setShowLoading(false)}, 1000)
            }
        }else{
            setCreateUserFailedReason(data.error)
            setCreateUserFailed(true)
            console.log("Creating user failed, reload the website:", res_status, data)
            setShowInvalidDataPopup(true)
            setShowExistingAccountFoundPopUp(false)

            setShowLoading(false)
            setPopupLoading(false)
        }
    }





    // ######################## helper function section ########################
    // ######################## helper function section ########################
    const validateZodiacSign = (given_sign) => {
        const given_sign_lower = given_sign.toLowerCase()
        return ["capricorn", "aquarius", "pisces", "aries", "taurus", "gemini", "cancer", "leo", "virgo", "libra",	"scorpio", "sagittarius"].includes(given_sign_lower)
    }


    const remove_local_storage = () => {
        localStorage.removeItem('prompt_uid')
        localStorage.removeItem('prompt_uuid')
        localStorage.removeItem('prompt_access_token')
        localStorage.removeItem('prompt_agent_name')
        localStorage.removeItem('prompt_chat_room')
        localStorage.removeItem("verify_subscription")
        dispatch(uiActions.setIsLoggedIn(false))
    }




    // #################################################### subscription handler ####################################################
    const handleSubscribe = async() => {
        setShowLoading(true)
        localStorage.setItem("verify_subscription", true)
        console.log("Get purchase link from backend and redirect to that:", prompt_uuid)
        const res = await fetch(`${API_URL}/api/v2/subscribe`, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${prompt_access_token}`,
            }
        })

        const res_status = res.status
        const data = await res.json()
        console.log('\n\n\nsubscribe res :', res_status, data, data['payment_link'])
        if(res_status===200){
            window.location.href = data['payment_link']    
        }else{
            localStorage.removeItem("verify_subscription")
            if(res_status===401){
                // access token expired so lets send the user to login page
                remove_local_storage()
                navigate('/login')
            }else{
                // internet issue or something went wrong
                setSubscriptionFailedMsg(data['error'])
                setShowSubscriptionFailed(true)
                setTimeout(() => {setShowSubscriptionFailed(false)}, 3000);
            }
        }
        setTimeout(() => {setShowLoading(false)}, 1000);
    }


          
    const initial_msg_page_props = {
        popupLoading,
        setPopupLoading,

        // existing account found props
        showExistingAccountFoundPopUp,
        create_user_account,
        setShowLoading,
        setShowExistingAccountFoundPopUp,
        initialization,

        // invalid data provided props
        showInvalidDataPopup,
        createUserFailed,
        createUserFailedReason,
        // setShowInvalidDataPopup,
        remove_local_storage,

        // network issue or somthing went wrong
        showSomethingWentWrongDiv,
        prompt_chatroom_key,
        prompt_agent_name,
        force_check_user_credentials,
    }

    const reset_ps_page_props = {
        popupLoading,
        setPopupLoading,
        force_check_user_credentials,
        remove_local_storage,
    }

    const navbar_props = {
       handleSubscribe,
       remove_local_storage,
    }

    const chat_props = {
        handleSubscribe,
        showSubscriptionFailed,
        subscriptionFailedMsg,
    }

    const success_props = {
        remove_local_storage,
        setShowLoading,
        showLoading,
    }


 

    return (
        <div className={classes.full_screen_center_center}>
            {showLoading?(
                <div className={classes.main_spinner_div}>
                    <GooSpinner/>
                </div>
            ):(
            <>
                {isLoggedIn && <NavBar {...navbar_props}/>}
                {/* <div className={classes.black_text}>MAIN : {window.location.pathname} {isLoggedIn?"TRUE":"FALSE"} {user} {user_email}</div> */}

                <Routes>
                    {isLoggedIn?(
                        <>
                        <Route path='/agent/:agent_name/:chatroom_key' exact element={<Chat {...chat_props}/>}/>
                        <Route path='/subscription/success' exact element={verify_subscription?<SuccessPage {...success_props}/>:<Navigate to={`/agent/${prompt_agent_name}/${prompt_chatroom_key}`}/>}/>
                        <Route path='/subscription/fail' exact element={verify_subscription?<FailedPage/>:<Navigate to={`/agent/${prompt_agent_name}/${prompt_chatroom_key}`}/>}/>
                        <Route path='/*' element={<Navigate to={`/agent/${prompt_agent_name}/${prompt_chatroom_key}`}/>}/>
                        </>
                    ):(
                        <>
                        <Route path='/login' exact element={<LoginPrompt validateEmail={validateEmail}/>}/>
                        <Route path='/reset' exact element={<ResetPassword {...reset_ps_page_props} isAdmin={false}/>}/>
                        <Route path='/agent/:agent_name/:chatroom_key' exact element={<Navigate to='/login'/>}/>
                        <Route path='/' exact element={(!showSomethingWentWrongDiv && !showInvalidDataPopup && !showExistingAccountFoundPopUp)?<Navigate to='/login'/>:<InitialMsgPage {...initial_msg_page_props}/>}/>
                        <Route path='/*' element={<Navigate to='/'/>}/>
                        </>
                    )}
                </Routes>
            </>)}
        </div>
    )
}
export default Main;