import { useState, useEffect } from 'react'
import clasess from '../SuccessPage/SuccessPage.module.scss'

import { useNavigate } from 'react-router-dom'



const FailedPage = () => {
    const navigate = useNavigate()
    const prompt_chatroom_key =  localStorage.getItem('prompt_chat_room', '')
    const prompt_agent_name = localStorage.getItem('prompt_agent_name', 'natasha')

    // states 
    const [tickTimer, setTickTimer] = useState(10)


    useEffect(()=>{
        if(tickTimer === 0){
            localStorage.removeItem("verify_subscription")
            navigate(`/agent/${prompt_agent_name}/${prompt_chatroom_key}`)
        }else{
            setTimeout(() => {setTickTimer(tickTimer-1)}, 1000);
        }
    }, [tickTimer])



    return ( 
        <div className={clasess.container}>
                <>
                    <h1>Sorry! Subscription Failed.</h1>
                    <p>Please try again with correct credentails. For support, contact admin at <span>support@astrowize.net.</span>
                    <br/>Redirecting to chat in {tickTimer} seconds.</p>
                </>
        </div>
     );
}
 
export default FailedPage;