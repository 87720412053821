import { createSlice } from '@reduxjs/toolkit'
// import names from '../utils/names_langs'

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        // language: {values: names.ENG, name:'english', code:'ENG'}, // all
        isLoggedIn: false, // user
        getSubscriptionData: true, // user
        
        isAdminLoggedIn: false, // admin
        // showAdminInterface: false, // admin
    },
    reducers: {
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload
        },
        setIsAdminLoggedIn(state, action) {
            state.isAdminLoggedIn = action.payload
        },
        setGetSubscriptionData(state, action) {
            state.getSubscriptionData = action.payload
        },
        // setShowAdminInterface(state, action) {
        //     state.showAdminInterface = action.payload
        // },
    }
})

export const uiActions = uiSlice.actions
export default uiSlice