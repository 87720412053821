import classes from './LoginPrompt.module.scss'

// components
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'

import {uiActions} from '../../store/ui-slice'
import {userActions} from '../../store/user-slice'
import {encryptData, validateEmail} from '../../utils/helper_functions'

import { useState } from 'react'
import {ImpulseSpinner} from 'react-spinners-kit'
import { useDispatch } from 'react-redux'



const LoginPrompt = (props) => {
    const dispatch = useDispatch()

    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT

    // all states
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [otp, setOtp] = useState('')
    const [otpRequired, setOtpRequired] = useState(true)

    const [loginLoading, setLoginLoading] = useState(false)
    const [showPasswordDiv, setShowPasswordDiv] = useState(false) 
    const [showSetupPasswordDiv, setShowSetupPasswordDiv] = useState(false)
    
    // alert states section
    const [showEmptyEmailAlert, setShowEmptyEmailAlert] = useState(false)
    const [showInvalidEmailAlert, setShowInvalidEmailAlert] = useState(false)

    const [showUserNotFoundAlert, setShowUserNotFoundAlert] = useState(false)
    const [showOTPValueError, setShowOTPValueError] = useState(false)

    // password issue alert
    const [showPasswordValueError, setShowPasswordValueError] = useState(false)
    const [showPasswordNotMatchedAlert, setShowPasswordNotMatchedAlert] = useState(false)

    // ps setup successful/failed alert
    const [showPasswordSetupSuccessAlert, setShowPasswordSetupSuccessAlert] = useState(false)
    const [showPasswordSetupFailedAlert, setShowPasswordSetupFailedAlert] = useState(false)

    // login successful/failed alert
    const [showLoginSuccessfulAlert, setShowLoginSuccessfulAlert] = useState(false)
    const [showLoginFailedAlert, setShowLoginFailedAlert] = useState(false)
    


    // ############################## useEffect section ##############################




    // ############################## all helper functions section ##############################
    // initial alert state 
    const initial_alert_state = () => {
        setShowEmptyEmailAlert(false)
        setShowInvalidEmailAlert(false)

        setShowUserNotFoundAlert(false)
        setShowOTPValueError(false)
    
        setShowPasswordValueError(false)
        setShowPasswordNotMatchedAlert(false)

        setShowPasswordSetupSuccessAlert(false)
        setShowPasswordSetupFailedAlert(false)
    
        setShowLoginSuccessfulAlert(false)
        setShowLoginFailedAlert(false)
    }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if(showPasswordDiv){
                handleLogin()

            }else{
                handleContinueToLogin()
            }
        }
    };   

    // ############################## all functions section ##############################
    const verify_password_status = async () => {
        initial_alert_state()

        console.log("Verifying password setup status :", email)
        const res = await fetch(`${API_URL}/api/v2/password`, {
            method: 'GET',
            headers: {
                'email':email
            }
        })

        const res_status = res.status
        const data = await res.json()
        console.log('password setup verification status :', res_status, data)

        if(res_status === 200){
            setShowPasswordDiv(true)
            if(!data.password_setup){
                setShowSetupPasswordDiv(true)
            }
        }else{
            console.log("No user found by given email:", email)
            setShowUserNotFoundAlert(true)
            setTimeout(()=>{setShowUserNotFoundAlert(false)}, 4000)
        }
        setLoginLoading(false)
    }


    const handleContinueToLogin = () => {
        initial_alert_state()
        console.log('Continue to login clicked :', email, loginLoading)

        if(email !== '' && validateEmail(email)){
            setLoginLoading(true)
            verify_password_status()
        }else{
            setShowEmptyEmailAlert(true)
            setTimeout(()=>{setShowEmptyEmailAlert(false)}, 4000)
        }
    }


    const handleLogin = async() => {
        initial_alert_state()
        const otp_int = parseInt(otp)

        console.log("Login btn clicked :", email, password, confirmPassword, otp_int, typeof otp_int, otp.length , showPasswordDiv, showSetupPasswordDiv, otpRequired)
        if(otpRequired){
            if(otp.length<4 || otp.length>6 || !Number.isInteger(otp_int)){
                setShowOTPValueError(true)
                setTimeout(()=>{setShowOTPValueError(false)}, 4000)
                return
            }
        }else{
            if(email===""){
                setShowEmptyEmailAlert(true)
                setTimeout(()=>{setShowEmptyEmailAlert(false)}, 4000)
                return
            }else if(!validateEmail(email)){
                setShowInvalidEmailAlert(true)
                setTimeout(()=>{setShowInvalidEmailAlert(false)}, 4000)
                return
            }
        }
    

        var api_endpoint;
        if(showSetupPasswordDiv){
            // setup password for email and then login
            if(password.length<8 || confirmPassword.length<8){
                setShowPasswordValueError(true)
                setTimeout(()=>{setShowPasswordValueError(false)}, 4000)
                return
            }
            else if(password !== confirmPassword){
                setShowPasswordNotMatchedAlert(true)
                setTimeout(()=>{setShowPasswordNotMatchedAlert(false)}, 4000)
                return
            }
            console.log('setting up password for :', email, password)
            api_endpoint = "/api/v2/password"
        }else{
            if(password.length<8){
                setShowPasswordValueError(true)
                setTimeout(()=>{setShowPasswordValueError(false)}, 4000)
                return
            }
            // login with email and password
            console.log('login with email and password :', email, password)
            api_endpoint = "/api/v2/login"
        }

        // sending request to api
        console.log("Req api_endpoint is :", api_endpoint)
        setLoginLoading(true)
        const res = await fetch(`${API_URL}${api_endpoint}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'email':email,
                'password':password,
                'otp':otp_int
            })
        })
        const res_status = res.status
        const data = await res.json()

        console.log('res in password setup/login is :', res_status, data)
        if(res_status === 200){
            if(showSetupPasswordDiv){
                setShowPasswordSetupSuccessAlert(true)
                setOtpRequired(false)
                setShowSetupPasswordDiv(false)
                setPassword('')

                setTimeout(()=>{
                    setShowPasswordSetupSuccessAlert(false)
                }, 4000)
            }else{
                console.log("login successful for :", email)
                setShowLoginSuccessfulAlert(true)

                // set the access token and other creds to localstorage
                localStorage.setItem('prompt_uid', encryptData(data['uid'], aes_salt_key))
                localStorage.setItem('prompt_uuid', encryptData(data['uuid'], aes_salt_key))
                localStorage.setItem('prompt_access_token', encryptData(data['access_token'], aes_salt_key))
                localStorage.setItem('prompt_chat_room', data['chat_token'])
                localStorage.setItem('prompt_agent_name', data['agent_name'])
                
                dispatch(userActions.setUser(data['uuid']))
                dispatch(userActions.setEmail(email))
                dispatch(userActions.setAccessToken(data['access_token']))

                setTimeout(()=>{
                    setShowLoginSuccessfulAlert(false)
                    dispatch(uiActions.setIsLoggedIn(true))
                }, 3000)
            }
        }else{
            if(showSetupPasswordDiv){
                // ps setup failed 
                setShowPasswordSetupFailedAlert(true)
                setTimeout(()=>{setShowPasswordSetupFailedAlert(false)}, 4000)
            }else{
                setShowLoginFailedAlert(true)
                setTimeout(()=>{setShowLoginFailedAlert(false)}, 4000)
            }
        }
        setLoginLoading(false)
    }



    return (
        <div className={classes.container}>
            <div className={classes.login_container}>
                {loginLoading ? (
                    <div className={classes.alert_loading_div}>
                        <ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>
                    </div>
                ):(
                    <div className={classes.login_container_form}>
                        <div className={classes.login_container_form_top}>
                            <div className={classes.title_div}>{`${showSetupPasswordDiv?"Password Setup":"Prompt Login"}`}</div>
                            <div className={classes.input_div}>
                                
                                {/* email, password and confirm password section */}
                                {showPasswordDiv?(
                                    <>
                                        {otpRequired?(
                                            <Input label='One Time Password' type='text' placeholder='OTP' value={otp} onChange={setOtp} onKeyDown={handleKeyDown}/>
                                        ):(
                                            <Input label='Email' type='email' placeholder={email!==""?email:'mail@example.com'} value={email} onChange={setEmail} onKeyDown={handleKeyDown}/>
                                        )}

                                        <Input label='Password' type='password' placeholder='********' value={password} onChange={setPassword} onKeyDown={handleKeyDown}/>
                                        {showSetupPasswordDiv && <Input label='Confirm Password' type='password' placeholder='********' value={confirmPassword} onChange={setConfirmPassword} onKeyDown={handleKeyDown}/>}
                                    </>
                                    
                                ):(<Input label='Email' type='email' placeholder={email!==""?email:'mail@example.com'} value={email} onChange={setEmail} onKeyDown={handleKeyDown}/>)}


                                {/* remark section */}
                                <div className={classes.login_remark}>
                                    {showPasswordDiv && showSetupPasswordDiv && "An otp is sent to your email address. Please use otp and set password to login."}
                                    {showPasswordDiv && !showSetupPasswordDiv && otpRequired && "An otp is sent to your email address. Please use otp and password to login."}
                                    {!showPasswordDiv && "Enter your email address and continue to login. An unforgettable spiritual & psychic experience is waiting for you!"}
                                </div>
                                <div className={classes.reset_remark}>
                                    Forgot your password?<a href='/reset'>Reset</a>
                                </div>


                            </div>
                        </div>
                        <div className={classes.login_container_form_bottom}>
                            <div className={classes.alert_div}>
                                {showEmptyEmailAlert && "Email can't be empty."}
                                {showInvalidEmailAlert && "Invalid Email. Please try again with correct one."}
                                {showUserNotFoundAlert && "Please provide a correct email and try again."}

                                {showOTPValueError && "Invalid OTP. Please provide a valid one."}
                                {showPasswordValueError && "Password fields can't be empty and must be 8 characters long."}
                                {showPasswordNotMatchedAlert && "Passwords not matched. Please check and try again."}

                                {showPasswordSetupSuccessAlert && <p className={classes.success_text}>Password is set succesfully. Login to continue.</p>}
                                {showPasswordSetupFailedAlert && "Password setup failed. Please try again."}

                                {showLoginSuccessfulAlert && <p className={classes.success_text}>Login successful. Please wait...</p>}
                                {showLoginFailedAlert && "Login failed. Please try again with correct credentials."}    
                            </div>
                            <div className={classes.btn_div}>
                                <Button reverse id='user_login_btn' color='green' btnText="Continue" onClick={showPasswordDiv?handleLogin:handleContinueToLogin}/>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LoginPrompt;