
import classes from '../LoginPrompt/LoginPrompt.module.scss'
import adminlogin_classes from '../AdminLogin/AdminLogin.module.scss'


// components
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { validateEmail } from '../../utils/helper_functions'

import {ImpulseSpinner} from 'react-spinners-kit'



const ResetPassword = (props) =>{
    const navigate = useNavigate()
    const isAdmin = props.isAdmin

    const API_URL = process.env.REACT_APP_API_URL


    // ################# reset states ##################
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [otp, setOtp] = useState('')
 
    const [resetLoading, setResetLoading] = useState(false)
    const [showPasswordDiv, setShowPasswordDiv] = useState(false) 

     
     // alert states section
     const [showEmptyEmailAlert, setShowEmptyEmailAlert] = useState(false)
     const [showInvalidEmailAlert, setShowInvalidEmailAlert] = useState(false)
 
     const [showUserNotFoundAlert, setShowUserNotFoundAlert] = useState(false)
     const [showOTPValueError, setShowOTPValueError] = useState(false)
 
     // password issue alert
     const [showPasswordValueError, setShowPasswordValueError] = useState(false)
     const [showPasswordNotMatchedAlert, setShowPasswordNotMatchedAlert] = useState(false)
 
     // ps setup successful/failed alert
     const [showPasswordResetSuccessAlert, setShowPasswordResetSuccessAlert] = useState(false)
     const [showPasswordResetFailedAlert, setShowPasswordResetFailedAlert] = useState(false)
 
     // login successful/failed alert
     const [showLoginSuccessfulAlert, setShowLoginSuccessfulAlert] = useState(false)
     const [showLoginFailedAlert, setShowLoginFailedAlert] = useState(false)


    // ################# helper functions ##################
    // initial alert state 
    const initial_alert_state = () => {
        setShowEmptyEmailAlert(false)
        setShowInvalidEmailAlert(false)

        setShowUserNotFoundAlert(false)
        setShowOTPValueError(false)
    
        setShowPasswordValueError(false)
        setShowPasswordNotMatchedAlert(false)

        setShowPasswordResetSuccessAlert(false)
        setShowPasswordResetFailedAlert(false)
    
        setShowLoginSuccessfulAlert(false)
        setShowLoginFailedAlert(false)
    }


    // if(isAdmin){
    //     handleAdminLogin()
    // }else{
    // }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if(showPasswordDiv){
                handleReset()
            }else{
                handleContinueToReset()
            }
        }
    };   


    // ################# main functions ##################
    const send_otp_to_email_for_reset = async () => {
        console.log("Sending otp to reset password :", email)
        const res = await fetch(`${API_URL}/api/v2/reset`, {
            method: 'GET',
            headers: {'email':email}
        })

        const res_status = res.status
        const data = await res.json()
        console.log('reset password email verification status :', res_status, data)

        if(res_status === 200){
            setShowPasswordDiv(true)
        }else{
            console.log("No user found by given email:", email)
            setShowUserNotFoundAlert(true)
            setTimeout(()=>{setShowUserNotFoundAlert(false)}, 4000)
        }
        setResetLoading(false)
    }

    const handleContinueToReset = () => {
        initial_alert_state()
        console.log('handleContinueToReset clicked  :', email, resetLoading)

        if(email !=='' && validateEmail(email)){
            setResetLoading(true)
            send_otp_to_email_for_reset()
        }else{
            setShowEmptyEmailAlert(true)
            setTimeout(()=>{setShowEmptyEmailAlert(false)}, 4000)
        }
    }


    const handleReset = async() => {
        setResetLoading(true)
        initial_alert_state()
        const otp_int = parseInt(otp)
        console.log("Handle Reset clicked :", email, password, confirmPassword, otp_int, otp.length, showPasswordDiv)
        if(otp.length<4 || otp.length>6 || !Number.isInteger(otp_int)){
            setShowOTPValueError(true)
            setResetLoading(false)
            setTimeout(()=>{setShowOTPValueError(false)}, 4000)
            return
        }else if(password.length<8 || confirmPassword.length<8){
            setShowPasswordValueError(true)
            setResetLoading(false)
            setTimeout(()=>{setShowPasswordValueError(false)}, 4000)
            return
        }
        else if(password !== confirmPassword){
            setShowPasswordNotMatchedAlert(true)
            setResetLoading(false)
            setTimeout(()=>{setShowPasswordNotMatchedAlert(false)}, 4000)
            return
        }
        const res = await fetch(`${API_URL}/api/v2/reset`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body:JSON.stringify(
                {
                    'email':email,
                    'password':password,
                    'otp':otp_int
                }
            )
        })
        const res_status = res.status
        const data = await res.json()
        console.log('reset password res :', res_status, data)
        if(res_status === 200){
            setShowPasswordResetSuccessAlert(true)
            setEmail('')
            setPassword('')
            setOtp('')

            setTimeout(()=>{
                setShowPasswordResetSuccessAlert(false)
                props.remove_local_storage()
                navigate('/login')
            }, 4000)
        }else{
            console.log("reset ps failed :", email)
            setShowPasswordResetFailedAlert(true)
            setTimeout(()=>{setShowPasswordResetFailedAlert(false)}, 4000)
        }
        setResetLoading(false)
    }


    return(
        <div className={isAdmin?adminlogin_classes.container:classes.container}>
            <div className={classes.login_container}> 
                {resetLoading ? (
                    <div className={classes.alert_loading_div}>
                        <ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>
                    </div>
                ):(
                    <div className={classes.login_container_form}>
                        <div className={classes.login_container_form_top}>
                            <div className={classes.title_div}>{isAdmin?"Reset Admin Password":"Reset Password"}</div>
                            <div className={classes.input_div}>
                                {/* email, password and confirm password section */}
                                {showPasswordDiv?(
                                    <>
                                    <Input label='One Time Password' type='text' placeholder='OTP' value={otp} onChange={setOtp} onKeyDown={handleKeyDown}/>
                                    <Input label='New Password' type='password' placeholder='********' value={password} onChange={setPassword} onKeyDown={handleKeyDown}/>
                                    <Input label='Confirm New Password' type='password' placeholder='********' value={confirmPassword} onChange={setConfirmPassword} onKeyDown={handleKeyDown}/>
                                    </>
                                ):(<Input label='Email' type='email' placeholder={email!==""?email:'mail@example.com'} value={email} onChange={setEmail} onKeyDown={handleKeyDown}/>)}

                                {/* remark section */}
                                <div className={classes.login_remark}>
                                    {showPasswordDiv && "An otp is sent to your email address. Please use otp and password to login."}
                                    {!showPasswordDiv && "Enter your email address and continue to reset password."}
                                </div>
                                <div className={classes.reset_remark}>
                                    Remeber password?
                                    <a href={isAdmin?'/admin/login':'/login'}>Login Instead</a>
                                </div>
                            </div>
                        </div>
                        <div className={classes.login_container_form_bottom}>
                            <div className={classes.alert_div}>
                                {showEmptyEmailAlert && "Email can't be empty."}
                                {showInvalidEmailAlert && "Invalid Email. Please try again with correct one."}
                                {showUserNotFoundAlert && "Please provide a correct email and try again."}

                                {showOTPValueError && "Invalid OTP. Please provide a valid one."}
                                {showPasswordValueError && "Password fields can't be empty and must be 8 characters long."}
                                {showPasswordNotMatchedAlert && "Passwords not matched. Please check and try again."}

                                {showPasswordResetSuccessAlert && <p className={classes.success_text}>Password is set succesfully. Login to continue.</p>}
                                {showPasswordResetFailedAlert && "Password setup failed. Please try again."}

                                {showLoginSuccessfulAlert && <p className={classes.success_text}>Login successful. Please wait...</p>}
                                {showLoginFailedAlert && "Login failed. Please try again with correct credentials."}
                            </div>
                            <div className={classes.btn_div}>
                                <Button reverse id='reset_ps_btn' color='green' btnText={showPasswordDiv?"Reset":"Continue"} onClick={showPasswordDiv?handleReset:handleContinueToReset}/>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default ResetPassword;